import { ContentBlockShape } from "@/models";
import { SettingsIcon } from "lucide-react";
import React from "react";

type Props = {
    contentBlock: ContentBlockShape;
};

const SimulationTools = ({ contentBlock }: Props) => {
    return (
        <div>
            <SettingsIcon className="h-4 w-4" />
        </div>
    );
};

export default SimulationTools;

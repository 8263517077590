import { AdminPageHeader } from "@/components/admin-components";
import { sapienRoute } from "@/inertia-utils/hooks";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import Authenticated from "@/Layouts/Authenticated";
import { Link } from "@inertiajs/react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { useState } from "react";
import DesignStudioLeftNavigation from "./DesignStudioLeftNavigation";
import Display from "../Display/Display";
import {
    useContentBlockState,
    useSetupContentBlockState,
} from "./useContentBlockState";
import QueryClient from "../QueryClient/QueryClient";
import { useSelectedSimulation } from "@/hooks";
import ContentBlockWidgets from "./content-block-editing/ContentBlockWidgets";
import "./design-tools.css";
import AddContentBlockWidget from "./AddContentBlockWidget";
import RoundListNavigation from "./RoundListNavigation";
type Props = SapienAdminPageProps & {
    roundOrLesson: App.Data.RoundOrLessonData;
    currentPageId?: string;
};

function DesignStudio({ roundOrLesson, currentPageId }: Props) {
    const [isLeftNavOpen, setIsLeftNavOpen] = useState(false);
    return (
        <Authenticated>
            <AdminPageHeader className="fixed left-0 right-0 top-0 z-50 mt-16">
                <div className="flex w-full items-center justify-between gap-4">
                    <div className="flex gap-4">
                        <h2 className="text-xl font-bold">
                            {roundOrLesson.simulation?.title}
                        </h2>
                        <h2 className="text-xl font-bold">
                            {roundOrLesson.title}
                        </h2>
                        <Link
                            className="flex items-center underline"
                            href={sapienRoute("e-learning.preview.show", {
                                lesson: roundOrLesson.id,
                            })}
                        >
                            <span>Preview</span>
                            <ChevronRight className="h-4 w-4" />
                        </Link>
                    </div>
                    <div className="flex items-center gap-2">
                        {roundOrLesson.simulation.published_at ? (
                            <button
                                className="rounded-md border border-slate-500 bg-sapien-blue px-4 py-2 text-white
                                    transition-colors hover:bg-white hover:text-sapien-blue"
                                // onClick={handlePublish}
                            >
                                Unpublish
                            </button>
                        ) : (
                            <button
                                className="rounded-md border border-slate-500 bg-white px-4 py-2 text-sapien-blue
                                    transition-colors hover:bg-sapien-blue hover:text-white"
                                // onClick={handlePublish}
                            >
                                Publish
                            </button>
                        )}
                    </div>
                </div>
            </AdminPageHeader>
            <div className="design-studio-container mt-16 flex flex-col gap-4">
                <DesignStudioLeftNavigation
                    isOpen={isLeftNavOpen}
                    setIsOpen={setIsLeftNavOpen}
                >
                    <div className="flex flex-col gap-4 p-6 pr-10">
                        <div className="absolute right-4 top-4">
                            <button
                                onClick={() => setIsLeftNavOpen(!isLeftNavOpen)}
                            >
                                <ChevronLeft
                                    className="h-4 w-4 transition-transform duration-300 data-[isopen=false]:rotate-180"
                                    data-isopen={isLeftNavOpen}
                                />
                            </button>
                        </div>
                        <RoundListNavigation
                            rounds={roundOrLesson.simulation.rounds}
                            currentPageId={currentPageId}
                            simulationId={roundOrLesson.simulation.id}
                            currentRoundId={roundOrLesson.id}
                        />
                    </div>
                </DesignStudioLeftNavigation>
                <div className="pl-[45px]">
                    <Display
                        isInAdminContext={true}
                        lesson={roundOrLesson}
                        lessonCount={1}
                    />
                </div>
                <ContentBlockWidgets />
                <AddContentBlockWidget
                    hasBlocks={!!roundOrLesson.contentBlocks.length}
                    roundId={roundOrLesson.id}
                />
            </div>
        </Authenticated>
    );
}

const WrappedLessonDesign = (props: Props) => {
    const { roundOrLesson } = props;
    useSetupContentBlockState(roundOrLesson);
    const { gotSimulation } = useSelectedSimulation();
    gotSimulation(roundOrLesson.simulation);
    // return <pre>{JSON.stringify(roundOrLesson.simulation, null, 2)}</pre>;
    return <QueryClient>{<DesignStudio {...props} />}</QueryClient>;
};

export default WrappedLessonDesign;

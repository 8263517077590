import { ContentBlockShape, RichText } from "@/models";
import { EditorContent } from "@tiptap/react";
import React, { useCallback, useEffect } from "react";
import { useDebouncedEditor } from "@/modules/text-editor/useDebouncedEditor";
import { RichTextTooltipMenu } from "@/components/rich-text";
import { useSaveContentBlock } from "../../Design/content-block-management/useSaveContentBlock";
import { useContentBlockState } from "../../Design/useContentBlockState";
import { useRichTextModelQuantities } from "./useRichTextModelQuantities";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { Editor } from "@tiptap/core";

type Props = {
    editable: boolean;
    content: RichText;
    contentBlock: ContentBlockShape;
    saveOverride?: (rich_text: RichText | App.Data.RichTextData) => void;
    delayMilliseconds?: number;
};

const RichTextBlock = React.memo(function RichTextBlock({
    editable = false,
    content,
    contentBlock,
    saveOverride,
    delayMilliseconds = 500,
}: Props) {
    const { mutateAsync } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const handleSave = useCallback(
        (rich_text: RichText | App.Data.RichTextData) => {
            mutateAsync({ ...contentBlock, rich_text: rich_text as RichText });
        },
        [contentBlock?.id, editable],
    );

    const editor = useDebouncedEditor({
        richText: content,
        isEditable: editable,
        updateOverride: saveOverride || handleSave,
        editedId: undefined,
        waitMilliseconds: delayMilliseconds,
        placeholder: "Add some text...",
        onBlur: async (rich_text) => {
            setContentBlock({
                ...contentBlock,
                rich_text: rich_text as RichText,
            });
            mutateAsync({ ...contentBlock, rich_text: rich_text as RichText });
        },
    });

    const { isLoading } = useRichTextModelQuantities();

    return (
        <span
            data-content-block-id={contentBlock.id}
            data-is-loading={isLoading}
            className="data-[is-loading=true]:animate-pulse"
        >
            {!!editor && editable && (
                <RichTextTooltipMenu editor={editor} showColorPicker={true} />
            )}

            <ModelQuantity  />

            <EditorContent editor={editor} className="w-full" />
        </span>
    );
});

function ModelQuantity() {
    const {
        modelValues: values,
        isLoading,
        elements,
    } = useRichTextModelQuantities();

    useEffect(() => {
        elements?.forEach((element) => {
            element.classList.add("animate-pulse");
            element.textContent = "Loading...";
            const key = !!element.getAttribute("data-time-horizon-id")
                ? element.getAttribute("data-model-variable-id") +
                  "_" +
                  element.getAttribute("data-time-horizon-id")
                : element.getAttribute("data-model-variable-id");

            const modelValue = values?.[key];

            if (
                !isLoading &&
                modelValue &&
                element.textContent !== modelValue
            ) {
                element.textContent = modelValue;
                element.classList.remove("animate-pulse");
            }
        });
    }, [isLoading, values, elements]);

    return <></>;
}

export default RichTextBlock;

import React, { memo } from "react";
import { ContentBlockDisplay } from "@/Pages/Admin/editor/blocks";
import { ContentBlockShape, ContentBlockType } from "@/models";
import LessonRichText from "./rich-text/RichTextBlock";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { createPortal } from "react-dom";
import LessonQuestion from "./content-block-components/QuestionBlock";
import LessonFormContentBlock from "./content-block-components/LessonFormContentBlock";
import FlipCard from "./content-block-components/flip-cards/FlipCard";
import FormBlock from "./content-block-components/FormBlock";
import Divider from "./content-block-components/Divider";
import LessonAccordion from "./content-block-components/accordion/LessonAccordion";
import LessonAccordionItem from "./content-block-components/accordion/LessonAccordionItem";
import LessonTabs from "./content-block-components/tabs/LessonTabs";
import { LessonImage } from "./ImageBlock";
import { defaultSimulationTheme, StyledBox } from "@/styles";
import {
    LessonTable,
    // LessonTableRow,
    // LessonTableCell,
    // LessonTableHeader,
    // LessonTableHead,
    // LessonTableBody,
} from "./content-block-components/tables/LessonTable";
import {
    useContentBlockState,
    useGetContentBlockWithChildren,
} from "../Design/useContentBlockState";
import ButtonBlock from "./content-block-components/ButtonBlock";
import SectionBlock from "./content-block-components/SectionBlock";
import { useSelectedSimulation } from "@/hooks";

type Props = {
    contentBlock: ContentBlockShape;
    scrollCallback?: (e) => void;
    isInAdminContext: boolean;
    isParentBlock?: boolean;
};

function ContentBlockRender({
    contentBlock,
    scrollCallback,
    isInAdminContext,
}: Props) {
    const { selectedSimulation } = useSelectedSimulation();
    const simulationTheme = selectedSimulation?.theme || defaultSimulationTheme;

    const children =
        useGetContentBlockWithChildren(contentBlock.id)?.contentBlocks.sort(
            (a, b) => a.weight - b.weight,
        ) || [];

    switch (contentBlock.content_block_type) {
        case ContentBlockType.Accordion:
            if (selectedSimulation?.is_course) {
                return (
                    <>
                        <LessonAccordion contentBlock={contentBlock}>
                            {children?.map((child) => (
                                <NestedContentBlockDisplay
                                    key={child.id}
                                    contentBlock={child}
                                    isInAdminContext={isInAdminContext}
                                />
                            ))}
                        </LessonAccordion>
                    </>
                );
            }
            return (
                <ContentBlockDisplay
                    key={contentBlock.id}
                    isSelected={false}
                    contentBlock={contentBlock}
                    isEditable={false}
                    simulationTheme={simulationTheme}
                    activeChildIndex={0}
                    homeRoute={""}
                    renderMap={{}}
                    reflectionJournalEntries={[]}
                    formProps={{
                        questionFormObject: {},
                        isSubmitting: false,
                    }}
                >
                    {children?.map((child) => (
                        <NestedContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
                </ContentBlockDisplay>
            );
        case ContentBlockType.AccordionItem:
            if (selectedSimulation?.is_course)
                return (
                    <LessonAccordionItem
                        contentBlock={contentBlock}
                        isInAdminContext={isInAdminContext}
                    >
                        {children?.map((child) => (
                            <NestedContentBlockDisplay
                                key={child.id}
                                contentBlock={child}
                                isInAdminContext={isInAdminContext}
                            />
                        ))}
                    </LessonAccordionItem>
                );
        case ContentBlockType.Box:
            return (
                <StyledBox
                    contentBlockId={contentBlock.id}
                    theme={contentBlock.theme}
                >
                    {children?.map((child) => (
                        <NestedContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
                </StyledBox>
            );
        case ContentBlockType.Button:
            return (
                <ButtonBlock
                    contentBlock={contentBlock}
                    isInAdminContext={isInAdminContext}
                />
            );
        case ContentBlockType.Divider:
            return <Divider contentBlock={contentBlock} />;
        case ContentBlockType["Flip Card"]:
            return (
                <FlipCard
                    contentBlock={contentBlock}
                    isInAdminContext={isInAdminContext}
                    sides={children}
                />
            );

        case ContentBlockType.Form:
            return (
                <LessonFormContentBlock
                    contentBlock={contentBlock}
                    isInAdminContext={isInAdminContext}
                >
                    {children
                        //filter so that forms can render and pass props to their own buttons
                        .filter(
                            (child) =>
                                child.content_block_type !==
                                ContentBlockType.Button,
                        )
                        ?.map((child) => (
                            <NestedContentBlockDisplay
                                key={child.id}
                                contentBlock={child}
                                isInAdminContext={isInAdminContext}
                            />
                        ))}
                </LessonFormContentBlock>
            );
        case ContentBlockType.Image:
            return (
                <LessonImage
                    contentBlock={contentBlock}
                    isInAdminContext={isInAdminContext}
                />
            );
        case ContentBlockType.Page:
            return (
                <main className="flex h-full w-full flex-col items-center">
                    {children?.map((child) => (
                        <NestedContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
                </main>
            );
        case ContentBlockType["Rich Text"]:
            return (
                <LessonRichText
                    contentBlock={contentBlock}
                    editable={isInAdminContext}
                    content={contentBlock.rich_text}
                />
            );

        case ContentBlockType.Question:
            return (
                <LessonQuestion
                    contentBlock={contentBlock}
                    editable={isInAdminContext}
                    content={contentBlock.rich_text}
                />
            );
        case ContentBlockType.Section:
            return (
                <SectionBlock
                    isInAdminContext={isInAdminContext}
                    contentBlock={contentBlock}
                >
                    {children?.map((child) => (
                        <NestedContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
                </SectionBlock>
            );
        case ContentBlockType.Table:
            return (
                <LessonTable
                    contentBlock={contentBlock}
                    isInAdminContext={isInAdminContext}
                    children={children}
                />
            );

        case ContentBlockType.Tabs:
            return (
                <LessonTabs
                    contentBlock={contentBlock}
                    contentBlocks={children}
                    isInAdminContext={isInAdminContext}
                />
            );

        default:
            return (
                <ContentBlockDisplay
                    key={contentBlock.id}
                    isSelected={false}
                    contentBlock={contentBlock}
                    isEditable={false}
                    simulationTheme={simulationTheme}
                    activeChildIndex={0}
                    homeRoute={""}
                    renderMap={{}}
                    reflectionJournalEntries={[]}
                    formProps={{
                        questionFormObject: {},
                        isSubmitting: false,
                    }}
                >
                    {children?.map((child) => (
                        <NestedContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
                </ContentBlockDisplay>
            );
    }
}

const NestedContentBlockDisplay = memo(function NestedContentBlockDisplay({
    contentBlock,
    scrollCallback,
    isInAdminContext = false,
}: Props) {
    const contentBlockEl = useGetElementAsync(
        `[data-content-block-id="${contentBlock.id}"]`,
    );

    const { editableContentBlockIds } = useContentBlockState();
    const isEditable = editableContentBlockIds.includes(contentBlock.id);
    // console.log("nested lesson content block display", contentBlock.content_block_type, contentBlock.id);
    if (!!contentBlockEl) contentBlockEl.style.position = "relative";

    return (
        <>
            {isInAdminContext && !!contentBlockEl && isEditable && (
                <>
                    <div
                        className="relative w-full p-0 transition-all"
                        data-design-studio-add-block-id={contentBlock.id}
                    ></div>
                    {createPortal(
                        <div
                            data-design-studio-content-block-id={
                                contentBlock.id
                            }
                            className={`pointer-events-none absolute inset-0 w-full transition-all delay-200
                                has-[.content-block-buttons]:border has-[.content-block-buttons]:border-l-0
                                has-[.content-block-buttons]:border-r-0
                                has-[.content-block-buttons]:border-gray-300/0
                                has-[.content-block-buttons]:first:border-t-0
                                has-[.content-block-buttons]:last:border-b-0
                                has-[.content-block-buttons]:hover:border-gray-300/100`}
                        ></div>,
                        contentBlockEl,
                    )}
                </>
            )}

            <ContentBlockRender
                {...{ contentBlock, scrollCallback, isInAdminContext }}
            />
        </>
    );
});
export default NestedContentBlockDisplay;
